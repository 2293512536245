.topbar {
    width: 100%;
    background: $color-menu-grey-top; /* For browsers that do not support gradients */
    background: -webkit-linear-gradient($color-menu-grey-top , $color-menu-grey-bottom); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient($color-menu-grey-top , $color-menu-grey-bottom); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient($color-menu-grey-top , $color-menu-grey-bottom); /* For Firefox 3.6 to 15 */
    background: linear-gradient($color-menu-grey-top , $color-menu-grey-bottom); /* Standard syntax */
    height: 60px;
    display: flex;
    flex-wrap: wrap;

    &__logo {
        display: inline-block;
        width: 72%;
        height: 100%;
        flex-grow: 1;
        .small,
        .big {
            display: block;
            margin-left: 10px;
            text-transform: uppercase;
            font-family: 'Droid Serif', 'serif';
            line-height: rem-calc(19);
            a {
                color: $color-logo-text;
            }
        }
        .small {
            font-size: rem-calc(15);
            padding-top: 11px;
        }
        .big {
            font-size: rem-calc(21);
        }

        @include breakpoint(medium) {
            width: auto;
            flex-grow: 0;
            .small,
            .big {
                margin-right: 10px;
                margin-left: 20px;
                line-height: rem-calc(18);
            }
            .small {
                font-size: rem-calc(13);
                padding-top: 11px;
            }
            .big {
                font-size: rem-calc(18);
            }
        }

        @include breakpoint(large) {
            width: auto;
            .small,
            .big {
                margin-left: 15px;
                margin-right: 35px;
            }
            .small {
                font-size: rem-calc(15);
                padding-top: 11px;
            }
            .big {
                font-size: rem-calc(21);
            }
        }
    }

    &__mainmenu {
        display: none;
        @include breakpoint(medium) {
            display: inline-block;
            width: auto;
            flex-grow: 1;
            .title-bar {
                display: block;
                background: none;
                .hamburger-icon {
                    @include hamburger($white, $dark-gray, 20px, 16px, 2px, 3);
                    background: none;
                    border: none;
                }
            }
            ul {
                li {
                    a,
                    &.current a {
                        color: $color-white;
                        height: 60px;
                        -webkit-transition: background-color 0.5s ease-out;
                        -moz-transition: background-color 0.5s ease-out;
                        -o-transition: background-color 0.5s ease-out;
                        transition: background-color 0.5s ease-out;
                        text-decoration: none;
                        font-family: 'Muli', 'sans-serif';
                        font-size: rem-calc(15.5);
                        line-height: 60px;
                        font-weight: 300;
                        padding: rem-calc(0 8);
                        @include breakpoint(large) {
                            font-size: rem-calc(17.5);
                            padding: rem-calc(0 12);
                        }
                        &:hover {
                            background-color: $color-light-blue;
                        }
                    }
                    &.current {
                        a {
                            background-color: $color-menu-item;
                        }
                    }
                }
            }
        }
    }

    &__search {
        float: right;
        display: inline-block;
        width: 15%;
        .search_container {
            display: none;
        }
        @include breakpoint(medium) {
            flex-grow: 5;
            .search_container {
                display: block;
                position: relative;
                width: 100%;
                height: 100%;
                .search-form {
                    padding: 11px 30px 11px 0;
                    input[type=text] {
                        margin: 0;
                        padding-left: 8px;
                        font-size: rem-calc(14);
                        width: 100%;
                        height: 38px;
                        border-radius: 7px;
                    }
                    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                        text-transform : lowercase;
                        color: $color-text;
                        font-size: rem-calc(14);
                    }
                    .vergrootglas {
                        outline: 0;
                        position: absolute;
                        top: 15px;
                        right: 34px;
                        width: 34px;
                        height: 30px;
                        font-size: rem-calc(11);
                        background-color: $color-search-black;
                        text-indent: -9999px;
                        border: 0;
                        border-radius: 7px;
                    }
                    .dropdown {
                        position: absolute;
                        top: 15px;
                        right: 71px;
                        width: 30%;
                        max-width: 185px;
                        height: 30px;
                        font-size: rem-calc(11);
                        background-image: url(/images/icons/arrow-down-white.png);
                        background-repeat: no-repeat;
                        background-size: 9px 7px;
                        background-position: right 11px;
                        padding: 0 5px 0 8px;
                        border: 0;
                        border-radius: 7px;
                        background-color: $color-active-album;
                        color: $color-white;
                        font-family: 'Muli', 'sans-serif';
                        text-transform: lowercase;
                    }
                }
                .search-info {
                    position: absolute;
                    top: 19px;
                    right: 4px;
                    width: 22px;
                    height: 22px;
                    border-radius: 50%;
                    background-color: $color-light-grey;
                    content: 'i';
                    font-family: 'Bree Serif', 'serif';
                    text-align: center;
                    display: block;
                    color: $color-text;
                    font-size: 15px;
                }
                &::before {
                    position: absolute;
                    top: 20px;
                    right: 43px;
                    font-family: 'fontello';
                    content: '\e810';
                    display: block;
                    color: $color-white;
                    font-size: 14px;
                    z-index: 3;
                    pointer-events: none;
                }
            }
        }
    }

    &__logout {
        display: inline-block;
        width: 14%;
        max-width: 75px;
        border-left: 1px solid $color-menu-item;
        flex-grow: 1;
        .logout-button {
            display: none;
        }
        .title-bar {
            display: block;
            background: none;
            width: 23px;
            margin: 18px auto;
            padding: 0;
            .title-bar-left {
                float: none;
            }
            @include breakpoint(medium) {
                display: none;
            }
            .hamburger-icon {
                @include hamburger($white, $dark-gray, 20px, 16px, 2px, 3);
                background: none;
                border: none;
            }
        }
        @include breakpoint(medium) {
            span {
                font-family: 'Muli', 'sans-serif';
                font-size: rem-calc(16);
                color: $color-white;
                font-weight: 300;
                line-height: rem-calc(60);
                display: block;
                text-align: center;
            }
            .logout-button {
                text-align: center;
                display: block;
                input[type="submit"] {
                    border: none;
                    background: transparent;
                    color: $color-white;
                    font-family: 'Muli', 'sans-serif';
                    font-size: rem-calc(13);
                    padding: 21px 5px;
                }
            }
            #login-form-wrapper {
                display: none;
            }
        }
    }
}

.search-big-screen {
    display: none;
  @include breakpoint(xlarge) {
    display: block;
  }
}

.search-toggle {
  display: block;
  width: 14%;
  max-width: 75px;
  position: relative;
  border-left: 1px solid $color-menu-item;
  .search-button {
    left: 28%;
    top: 24%;
    position: absolute;
    color: $color-white;
    font-size: rem-calc(21);
    outline: 0;
    background-color:  transparent;
  }
  button {
    border: none;
    padding: 0;
    margin: 0;
  }
  @include breakpoint(xlarge) {
    display: none;
  }
}

.search-small-screen {
  display: none;
}

.hidden-search-container {
  width: 100%;
  height: auto;
  background-color: $color-menu-item;
    .search_container {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0 20px;
        .search-form {
            padding: 11px 30px 11px 0;
            // margin: 0 20% 0 260px;
            input[type=text] {
                margin: 0;
                padding-left: 8px;
                font-size: rem-calc(12);
                width: 100%;
                height: 38px;
                border-radius: 7px;
            }
            ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                text-transform : lowercase;
                color: $color-text;
                font-size: rem-calc(14);
            }
            .vergrootglas {
                position: absolute;
                top: 15px;
                right: 54px;
                width: 34px;
                height: 30px;
                font-size: rem-calc(11);
                background-color: $color-search-black;
                text-indent: -9999px;
                border: 0;
                border-radius: 7px;
            }
            .dropdown {
                position: absolute;
                top: 15px;
                right: 91px;
                width: 30%;
                max-width: 185px;
                height: 30px;
                font-size: rem-calc(11);
                background-image: url(/images/icons/arrow-down-white.png);
                background-repeat: no-repeat;
                background-size: 9px 7px;
                background-position: right 11px;
                padding: 0 5px 0 8px;
                border: 0;
                border-radius: 7px;
                background-color: $color-active-album;
                color: $color-white;
                font-family: 'Muli', 'sans-serif';
                text-transform: lowercase;
            }
        }
        .search-info {
            position: absolute;
            top: 19px;
            right: 15px;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            background-color: $color-light-grey;
            content: 'i';
            font-family: 'Bree Serif', 'serif';
            text-align: center;
            display: block;
            color: $color-text;
            font-size: 15px;
        }         
        &::before {
            pointer-events: none;
            position: absolute;
            top: 20px;
            right: 63px;
            font-family: 'fontello';
            content: '\e810';
            display: block;
            color: $color-white;
            font-size: 14px;
            z-index: 3;
        }

  }
  #dialog-search-info {
    display: none;
  }
}
