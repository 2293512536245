.category {

    .content-category  {
        padding: 0 5%;
        margin-bottom: 65px;

        .list-edit {
            display: none;
        }

        .category-desc h2 {
            padding: 40px 0;
        }

        tbody {
            border: none;

            tr:nth-child(even) {
                background-color: $color-album-divider;
            }

            tr:nth-child(odd) {
                background-color: $color-gray-black;
            }

        }

        .hasTooltip {
            display: none;
        }

    }

}

.form.edit {

    .amc-content {
        padding: 40px 5% 7%;

        fieldset {
            border: none;
            padding: 0;
            margin: 0;

            label {
                color: #fff;
            }

            #jform_articletext {
                height: 400px !important;
            }

            .control-group {
                margin: 5px 0;

                .controls {
                    color: #fff;
                }

                .inputbox {
                    color: $color-text;
                }


                #jform_tags,
                #jform_tags-lbl {
                    display: none;
                }

            }

        }

        .btn-group {
            display: inline-block;
            margin: 10px 0;

            button {
                border: none;
                background-color: $color-border-homepage;
                color: $color-meta-data-other;
                padding: 3px 10px;
                border-radius: 5px;
                font-size: 0.875rem;
                &:hover {
                    color: $color-white;
                }

            }

        }

    }

}
