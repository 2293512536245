.off-canvas .close-button {
    border: 0;
    background: transparent;
    padding: 0;
    font-size: 1.5em;
    color: $color-light-grey;
    position: static;
    padding: 17px;
}

.is-open {
    background-color: $color-dark-grey;
    a {
        color: $color-light-grey;
        &:hover {
            color: $color-light-blue;
        }
    }
}
