.bottom-content {
  width: 100%;
  // background: $color-dark-grey;
  height: auto;
  padding: 0 5%;
  h3 {
    font-family: 'Muli', sans-serif;
    font-size: rem-calc(19);
    color: $color-logo-text;
    margin-bottom: 5px;
  }
  .flex-container {
    -webkit-column-count: 3; /* Chrome, Safari, Opera */
    -moz-column-count: 3; /* Firefox */
    column-count: 3;
    display: block;
    margin: -10px;
    @media only screen and (max-width: 799px) {
      -webkit-column-count: 2; /* Chrome, Safari, Opera */
      -moz-column-count: 2; /* Firefox */
      column-count: 2;
    }
    @media only screen and (max-width: 414px) {
      -webkit-column-count: 1; /* Chrome, Safari, Opera */
      -moz-column-count: 1; /* Firefox */
      column-count: 1;
    }
    li {
      padding: 10px;
      margin: 10px 0;
      list-style-type: none;
      height: 100%;
      width: 100%;
      display: inline-block;
      .detail {
        font-family: 'Muli', sans-serif;
        font-size: rem-calc(12);
        color: $color-logo-text;
        font-weight: 300;
        margin: 0;
      }
      .comment {
        font-family: 'Muli', sans-serif;
        font-size: rem-calc(16);
        color: $color-light-blue;
        font-weight: 300;
        &:hover {
            color: $color-white;
        }
      }
    }
  }
}
