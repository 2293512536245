body {
    // min-height: 100vh;
    height: 100% !important;
    background-color: $color-background-content;
}

.topviewer-active {
    overflow: hidden;
}

.off-canvas-content {
    min-height: 100vh;
    background-color: $color-dark-grey;
}

.off-canvas-content {
    background-color: $color-background-content;
}

#dialog-search-info {
    p {
        text-align: left;
        padding: 5px 10px;
    }

    ul {
        padding: 0 10px;
    }
}

.profile .amc-content{
    width: 80%;
    margin: 40px auto 20px;

    fieldset,
    label {
        color: $color-white;

        dd {
            color: #9A9A9A;
        }
    }

    button,
    .btn {
        background-color: $color-border-homepage;
        color: $color-meta-data-other;
        padding: 3px 10px;
        border-radius: 5px;
        font-size: 0.875rem;
        margin: 10px;
        border: 0;
        display: inline-block;
        &:hover {
            color: $color-white;
        }
    }
}
