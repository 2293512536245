$color-black: #000000;
$color-white: #ffffff;
$color-gray-black: #333;
$color-text: $color-gray-black;
$color-border-homepage: #555555;
$color-active-album: #777777;
$color-meta-data: #888888;

$color-light-grey: #bbbbbb;
$color-logo-text: #bbbbbb;
$color-filter: #dddddd;

$color-background-content: #202020;
$color-album-divider: #2C2C2C;
$color-menu-grey-bottom: #2E2E2E;
$color-search-black: #262626;
$color-dark-grey: #262626;
$color-menu-grey-top: #3D3D3D;
$color-menu-border: #414141;
$color-menu-item: #525252;
$color-light-blue: #57B9C8;
$color-light-blue-hover: #94D2DC;
$color-slider-active: #9B9B9B;

$color-meta-data-other: #C7C7C7;
$color-bio-text: #B7B7B7;
$color-not-active-album: #D8D8D8;
$album-toggle-active: #FEFEFE;
$album-toggle-not-active: #A0A0A0;
