
.album-overview-container {
  .results {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .jscroll-inner {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;

      .result {
        flex-basis: auto
      }

    }
  }
}

.image-section {

  img {
    top: 50% !important;
  }

}

.image-box {
  border: 1px solid $color-menu-border;
  margin: 10px;
  width: 280px;
  height: 300px;
	position: relative;
	display: inline-block;
  &:hover  {
    border: 1px solid $color-light-grey;
  }

  .items {
    width: 100%;
    background: $color-album-divider;
    height: 35px;
    position: absolute;
    bottom: 0;

    .info {
        color: $color-light-grey;
        font-style: italic;
        background-color: $color-menu-border;
        padding: 0px 11px;
        position: absolute;
        left: 5px;
        top: 5px;
        font-size: rem-calc(16);
        font-weight: bold;
        text-transform: lowercase;
    }

    .info-icons:first-child {
      margin-left: 45px;
    }

    .info-icons {
      margin-top: 5px;
      display: inline-block;
      color: $color-light-grey;
      background-color: $color-menu-border;
      padding: 0px 7px;
      font-size: rem-calc(16);
      width: 26px;
      height: 24px;
    }

    .album, .folder {
      color: $color-light-grey;
      font-family: 'Muli', sans-serif;
      margin-left: 41px;
      font-size: rem-calc(14);
      vertical-align: sub;
    }

  }

  .album-page-image {
    max-width: 250px;
    max-height: 275px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

}

.gallery-overview {

  img {
    padding: 4px 0;
    position: absolute;
    top: 44%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .metadata {
    position: absolute;
    background-color: rgba(17, 17, 17, 0.85);
    width: 100%;
    margin: 0;
    padding: 0 5px;
    max-height: 263px;
    overflow: auto;
    display: none;

    li {
        padding: 2px 0;

        .album-label {
            color: $color-meta-data;
            margin: 0;
        }

        span {
            color: $color-meta-data-other;
            font-size: rem-calc(14);

            a {
                color: $color-light-blue;
            }

        }

    }

  }

}
