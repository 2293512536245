p {
    font-size: rem-calc(16);
    line-height: rem-calc(24);
    color: $color-light-grey;
    font-family: 'Muli', sans-serif;
}

.article-container p {
  margin-bottom: 1rem;
}

a {
    color: $color-light-blue;
    font-weight: 400;
    outline: 0;
    &:hover,
    &:focus {
      color: $color-white;
    }
}

h1, h2, h3 {
    color: $color-light-grey;
    font-family: 'Muli', sans-serif;
    margin-top: 0;
    margin-bottom: rem-calc(12);
    font-style: normal;
    line-height: 1;
}

h1 {
    font-size: rem-calc(36);
}

h2 {
    font-size: rem-calc(30);
}

h3 {
    font-size: rem-calc(24);
}

// Standard heading styling for meta data pop-up

#related_links_form .newlink,
.comment_form h2,
.edit-metadata-container h3  {
    font-size: rem-calc(24);
    color: $color-text;
}

// Typography Defaults
em,
i {
    font-style: italic;
    line-height: inherit;
}

strong,
b {
    font-weight: 700;
    line-height: inherit;
}

small {
    font-size: rm-calc(14);
    line-height: inherit;
}

// Lists
ul,
ol,
dl {
    font-size: rem-calc(16);
    line-height: 1.5;
    margin-bottom: rem-calc(24);
    list-style-position: outside;
    font-family: inherit;
}

ul {
    // margin-left: 20px;
    margin: 0;
    list-style-type: disc;
    color: $color-light-grey;
}

li {
    list-style-type: none;
}

ol {
    list-style-position: inside;
}

dl {
    dt {
        font-style: normal;
        font-size: rem-calc(16);
        font-weight: 400;
        line-height: rem-calc(24);
    }

    dd {
        margin-bottom: rem-calc(12);
    }
}
