.facetlist {
    li {
        margin-left: 20px;
        span {
            color: $color-light-blue !important;
        }
    }
}

.ui-dialog-title {
    background: none !important;
    padding: 0 !important;
    margin: 5px !important;
    color: white !important;
}

.ui-dialog-titlebar-close {
    line-height: 2 !important;
    text-indent: inherit !important;
    background-color: $color-border-homepage !important;
    padding: 3px 10px !important;
    text-align: center !important;
    border-radius: 5px !important;
    font-size: 0.875rem !important;

    &:hover {
        color: $color-white !important;
    }
}

.permalink-container {
    width: 100%;
    position: relative;
    margin: 15px 0;

    label {
        float: left;
    }

    div {
        overflow: hidden;
    }

    #permalink_input {
        width: 100% !important;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: block;
        padding: 0 5px !important;
    }

    &:after,
    &:before {
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: $color-white;
        content: '';
    }

    &:after {
        bottom: -10px;
        left: 0;
    }

    &:before {
        top: -10px;
        left: 0;
    }

}

.additional-scans li {
	display: inline-block;
}

.related-images-container {
    position: relative;
	margin-bottom: 10px;

	.album-label {
		display: block;
		min-height: 30px;
	}

    li {
        display: inline-block;
    }

    &:after {
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: $color-white;
        content: '';
        bottom: -4px;
        left: 0;
    }
}

.album-overview-container {

    .image_paginator {
        display: none;
    }

    .headers-images {
        display: flex;
        flex-wrap: nowrap;
        min-height: 40px;
        width: 100%;

        li {
            background-color: $color-album-divider;
            padding: 7px 10px;
            &:not(:last-child) {
                border-right: 2px solid $color-background-content;
            }

            a {
                color: $color-filter;
                font-size: rem-calc(14);
                font-weight: 200;
            }

        }

        .after-before-item {
            position: relative;
            padding-right:20px;
            &::before {
                font-size: 12px;
                position: absolute;
                right: 10px;
                top: 8px;
                color: $color-filter;
                font-family: 'fontello';
                content: '\e804';
            }
            &.sort-desc {
                &::before {
                    color:$color-active-album;
                }
            }
            &::after {
                font-size: 12px;
                position: absolute;
                right: 10px;
                top: 15px;
                color: $color-filter;
                font-family: 'fontello';
                content: '\e800';
            }
            &.sort-asc {
                &::after {
                    color:$color-active-album;
                }
            }
        }

        .rl-image,
        .rl-dates {
            flex-basis: 12%;
        }

        .rl-description {
            flex-basis: 16%;
        }

        .rl-location,
        .rl-added,
        .rl-keywords,
        .rl-unit,
        .rl-vehicles,
        .rl-vessels {
            flex-basis: 10%;
        }

    }

    .headers {
        display: flex;
        // flex-wrap: wrap;
        min-height: 40px;

        li {
            background-color: $color-album-divider;
            padding: 7px 10px;
            &:not(:last-child) {
                border-right: 2px solid $color-background-content;
            }
            a {
                color: $color-filter;
                font-size: rem-calc(14);
                font-weight: 200;
                width: 100%;
                height: 100%;
                display: inline-block;
                padding: 2px;
            }

        }

        .after-before-item {
            position: relative;
            padding-right:20px;
            &::before {
                font-size: 12px;
                position: absolute;
                right: 10px;
                top: 8px;
                color: $color-filter;
                font-family: 'fontello';
                content: '\e804';
            }
            &.sort-desc {
                &::before {
                    color:$color-active-album;
                }
            }
            &::after {
                font-size: 12px;
                position: absolute;
                right: 10px;
                top: 15px;
                color: $color-filter;
                font-family: 'fontello';
                content: '\e800';
            }
            &.sort-asc {
                &::after {
                    color:$color-active-album;
                }
            }
        }

        .rl-image,
        .rl-amcno,
        .rl-lfa,
        .rl-added
        {
            flex-basis: 12.5%;
        }

        .rl-title {
            flex-basis: 20%;
        }

        .rl-description {
            flex-basis: 30%;
        }
    }

    .results {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .jscroll-inner {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .result {
                flex-basis: auto
            }

        }

    }

    .overview-header {
        display: flex;
        justify-content: space-between;
        align-content: space-between;
        margin: 15px 0 20px;
        padding: 0 20px;

        .overview-pagination {
            display: block;

            span {
                color: $color-filter;
                font-weight: 100;
            }

        }

        .view-switch {
            display: block;


            ul {

                .list,
                .gallery {
                    display: inline-block;
                    background-color: $color-menu-border;
                    padding: 3px 8px;
                    a {
                        color: $album-toggle-not-active;
                        i {
                            font-size: rem-calc(16);
                        }
                    }
                }

                .active {
                    a {
                        color: $album-toggle-active !important;
                    }
                }

                .list {
                    border-radius: 8px 0 0 8px;
                }

                .gallery {
                    border-radius: 0 8px 8px 0;
                }

            }

        }

    }

}

.display-block {
    display: block !important;
}

.filter-container {
    background: $color-menu-item;

    .dropdown {
        display: inline-flex;

        &:nth-child(even) {
            border-right: 1px solid $color-menu-grey-bottom;
            border-left: 1px solid $color-menu-grey-bottom;
        }

        .is-dropdown-submenu-parent {
            position: relative;

            .filter-item {
                font-size: rem-calc(16);
                color: $color-filter;
                &::before {
                    font-size: 12px;
                    position: absolute;
                    right: 10px;
                    top: 15px;
                    color: $color-filter;
                    font-family: 'fontello';
                    content: '\e800';
                }
                &::after {
                    display: none !important;
                }
            }

            ul {
                background: $color-menu-item;
                border: none;

                li a:hover {
                    background: $color-album-divider;
                }

                .menu-item-selected {
                    display: none;
                }

                .more a {
                    font-size: rem-calc(15);

                    @include breakpoint(medium) {
                        font-size: rem-calc(13);
                    }

                }

                .label {
                    color: $color-filter;
                    font-size: rem-calc(14);
                    background: none;
                    cursor: pointer;
                    padding: 0;
                }

                .count {
                    background: none;
                    color: $color-filter;
                    font-size: rem-calc(13);
                    padding: 0 0 0 5px;
                    background: none;
                    cursor: pointer;

                }

            }

        }

    }

}

.filtered-container {
    padding-left: 17px;
    padding-top: 10px;

    .filtered-title {
        font-size: rem-calc(16);
        display: none;
    }

    .filtered {
        margin: 0;
        padding: 0;
        list-style-type: none;

        .filtered-item {
            font-size: rem-calc(14);
            a {
                color: $color-filter;
                i {
                    font-size: 70%;
                }
            }
        }

        .filter-with-x::after {
                font-size: 13px;
                padding-left: 7px;
                color: $color-filter;
                font-family: 'fontello';
                content: '\e80b';
        }

    }

}

.list-view-container-images {

    .list-view-flex {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid $color-menu-border;
        width: 100%;

        a {
            color: $color-light-blue;
            font-size: rem-calc(14);
            &:hover {
                color: $color-light-blue-hover;
            }
        }

        div,
        p {
            color: $color-light-grey;
            font-weight: 100;
            font-size: rem-calc(14);
        }

        .flex-item-list {
            padding: 0px 15px 15px 5px;
        }

        .part-of {
            margin-left: 0px;
            width: 100%;
            padding: 15px 15px 5px 15px;
        }

        .rl-image {
            flex-basis: 12%;
            text-align:center;
            img {
                max-width: 100%;
                max-height:250px;
                padding: 0px 15px 5px 15px;
            }

        }

        .rl-image,
        .rl-dates {
            flex-basis: 12%;
        }

        .rl-description {
            flex-basis: 16%;
        }

        .rl-location,
        .rl-added,
        .rl-keywords,
        .rl-unit,
        .rl-vehicles,
        .rl-vessels {
            flex-basis: 10%;
        }

    }

}

.list-view-container {

    .list-view-flex {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid $color-menu-border;
        width: 100%;

        p {
            font-weight: 100;
            font-size: rem-calc(14);
        }

        .rl-image {
            flex-basis: 12%;
            text-align:center;

            img {

                max-width: 100%;
                max-height:250px;
                padding: 15px;
            }

        }

        .flex-item-list {
            padding: 15px 15px 15px 5px;

        }


        .rl-image,
        .rl-amcno,
        .rl-lfa,
        .rl-added
        {
            flex-basis: 12.5%;
        }

        .rl-description {
            flex-basis: 30%;
        }

        .rl-title {
            flex-basis: 20%;

            a {
                color: $color-light-blue;
                font-size: rem-calc(14);
                &:hover {
                    color: $color-light-blue-hover;
                }
            }

        }
    }

    .part-of {
        width: 100%;
        margin-left: 12%;
        border-top: 1px solid $color-menu-border;
        padding: 15px 5px;
        font-size: rem-calc(14);
        color: $color-light-grey;
    }

}

// more overlay for the filter
.reveal-overlay {
    background-color: rgba(17, 17,17, 0.85);
    padding: 50px;

    .reveal-modal {
        outline: 0;
    }

    span {
        background: none;
        color: $color-filter;
        font-size: rem-calc(14);
        padding: 3px 0;
        display: inline-block;
        white-space: normal;
    }

    .close-more-overlay {
        font-size: rem-calc(18);
        display: none;
    }

    .contentheading {
        margin: 10px 0 10px 20px;
        color: $color-meta-data;
    }

    .resultinformation {
        margin-left: 20px;
    }

    .facet_prefix {
        margin-left: 20px;
        a {
            color: $color-filter;
            font-size: rem-calc(14);
        }
    }

    .facetlist {
        column-count: 2;
        @include breakpoint(medium) {
            column-count: 3;
        }
        @include breakpoint(large) {
            column-count: 4;
        }
        @include breakpoint(xlarge) {
            column-count: 5;
        }
    }

}

.albumpage-links-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: $color-menu-item;
    height: 50px;

    .flex-item {
        flex-grow: 1;
        position: relative;
        text-align: center;

        .no-link {
            color: $color-menu-grey-top;
        }

        span {
            color: $color-filter;
        }

        a {
            font-size: rem-calc(14);
        }

        span {
            font-size: rem-calc(14);
        }

        .editable_field_button {
            color:$color-meta-data;
            font-size:12px;
            @extend .navigation-before-after;
            &:hover {
                color:#fff;
                font-size: 18px;
            }
        }
        &:hover {
            .editable_field_button {
                font-size: 18px;
            }
        }

    }

    .previous-page {
        display: none;

        @include breakpoint(medium) {
            display: inline;
            flex-basis: 10%;
        }
        @include breakpoint(large) {
            display: inline;
            flex-basis: 5%;
        }

        .back-to-results {
            position: absolute;
            left: 0px;
            top: -25px;
            height: 50px;
            width: 100%;

            i {
                padding: 14px 0;
                text-align: center;
                display: inline-block;
            }
        }

        &::after {
            position: absolute;
            display: block;
            content: '';
            height: 50px;
            background-color: $color-menu-grey-bottom;
            top: -25px;
            right: 0;
        }

    }

    .album-title {
        flex-grow: 2;
        flex-basis: 100%;
        padding:0 10px 0 15px;

        @include breakpoint(medium) {
            flex-basis: 60%;
            text-align: left;

            span {
                display: inline;
            }

        }

        // @include breakpoint(large) {
        //
        //     span, a {
        //         font-size: rem-calc(16);
        //     }
        //
        // }

    }

    .previous-album,
    .next-album {
        display: none;

        @include breakpoint(medium) {
            display: inline;
        }

    }

    .previous-album {

        @include breakpoint(medium) {

            &::after {
                position: absolute;
                display: block;
                content: '';
                width: 1px;
                min-height: 50px;
                height: auto;
                background-color: $color-menu-grey-bottom;
                top: -13px;
                right: 0;
            }

            &::before {
                position: absolute;
                display: block;
                content: '';
                width: 1px;
                min-height: 50px;
                height: auto;
                background-color: $color-menu-grey-bottom;
                top: -13px;
                left: 0;
            }

        }

    }

}

    .sort-list {
			position: absolute;
			z-index: 1;

        .meta-data {
            outline: 0;
            display: block;
            border: none;
            padding: 0;
            margin: 0;
            background-color: transparent;
        }
    }
    .sort-gallery {
			position: absolute;
			right: 0;
			z-index: 1;

        .next-pages {
            outline: 0;
            display: block;
            border: none;
            padding: 0;
            margin: 0;
            background-color: transparent;
        }
    }

    .flex-item {
        position: relative;
        text-align: center;

        img {
            display: block;
        }

    }

.editable_field {
    &:hover {
        .editable_field_button {
            font-size: 16px;
        }
    }
}
.editable_field_button {
    font-size:10px;
    color:$color-border-homepage;
    &:hover {
        color:#fff;
        font-size: 16px;
    }
}

.metadata-extend {

    ul {
        margin: 0;
        line-height: 1.3;

        li {
            padding: 2px 0;

            .album-label {
                color: $color-meta-data;
                margin: 0;
            }

            &:hover {
                .editable_field_button {
                    font-size: 16px;
                }
            }

            span {
                color: $color-meta-data-other;
                margin-left: 8px;
                font-size: rem-calc(14);

                a {
                    color: $color-light-blue;
                }
                &.no-label {
                    margin-left: 0px;
                }
            }

        }

    }

}

.album-label {
    font-size: 0.875rem;
    color: #888888;
}

.albumpage-container {
    width: 100%;
    padding: 20px;
    @extend .metadata-extend;

    @include breakpoint(medium) {
        padding-left: 5px;
        width: 50%;
        display: inline-block;
        float: left;
    }

    @include breakpoint(large) {
        padding-left: 15px;
        width: 30%;
    }

    @include breakpoint(xlarge) {
        padding-left: 15px;
        width: 25%;
    }

}

.results-container {
    width: 100%;

    @include breakpoint(medium) {
        width: 50%;
        display: inline-block;
    }

    @include breakpoint(large) {
        width: 70%;
        display: inline-block;
    }

    @include breakpoint(xlarge) {
        width: 75%;
        display: inline-block;
    }

}

.close-more-overlay,
#submit_button-label {
    display: none;
}

#showname-label,
#showname-element {
    display: inline-block;
    margin-right: 10px;
}

.type-links,
.add_lightbox,
.edit_metadata,
.request_caption,
.add_element,
#add_comment,
#download_image,
.filter-with-x,
.add-link,
.go-to-image {
    display: inline-block;
    background-color: $color-border-homepage;
    color: $color-meta-data-other;
    padding: 3px 10px;
    text-align: center;
    border-radius: 5px;
    font-size: 0.875rem;
    margin: 5px 0;
    &:hover {
        color: $color-white;
    }
}

.go-to-image {
    float: right;
    width: 200px;
}

.metadata_submit,
.metadata_submit_and_add,
.metadata_cancel,
.related-images-cancel,
.related-images-submit,
#submit_button,
.comment_form input {
    background-color: $color-border-homepage;
    color: $color-meta-data-other;
    border-radius: 5px;
    border: none;
    font-size: 0.875rem;
    padding: 5px 30px;
    margin-right: 10px;
    outline: none;
    &:hover {
        color: $color-white;
    }
}

.albumpage-content {
    position: relative;

    .meta-data-container {
        position: absolute;
        width: 100%;
        height: 38px;
        top: 0px;
        z-index: 2;

        @include breakpoint(medium) {
            width: 30%;
            min-width: 260px;
        }

        .meta-data-close {
            height: 31px;
            background-color: $color-active-album;
            position: relative;
            border-radius: 0 5px 0 0;

            .close-meta-data {

                &::after {
                    font-size: rem-calc(22);
                    color: $color-white;
                    font-family: 'fontello';
                    content: '\e80b';
                    position: absolute;
                    right: 12px;
                    top: -1px;
                }

            }
        }

        .albumpage-content {
            background-color: rgba(17, 17,17, 0.85);
            padding: 10px 20px 20px;
            border-radius: 0 0 10px 0;
            overflow: auto;
            max-height: calc(100vh - 145px);
            @extend .metadata-extend;

            .comment_list p {
                margin: 5px 0;
                color: $color-meta-data-other;
                font-size: rem-calc(15);
            }

            #permalink_input {
                padding: 1px 5px;
                width: auto;
                margin-left: 8px;
                font-size: 14px;
            }

            .related-images {
                display: inline-block;
            }

        }

    }

}

.ui-autocomplete {
	max-height: 400px;
	overflow-y: auto;
}

.ui-menu-item {
	cursor: pointer;
}

.skos-autocomplete {
    li:first-child {
        .ui-menu-item-wrapper {
            margin: -5px -20px 0px -20px;
            padding: 5px 20px;
            background: $color-light-grey;
            color: $color-black;

            &.ui-state-focus,
            &.ui-state-active {
                background: $color-light-blue;
            }
        }
    }
}

.ui-menu .ui-state-focus,
.ui-menu .ui-state-active {
        background: $color-light-blue;
}

.image-number {
    // display: none;
    padding: 4px 0 0 8px;
}

.album-pages-right {
    position: absolute;
    top: 110px;
    z-index: 2;
    right: 0;
    width: 100%;
    min-width: 290px;
    background: $color-black;
    overflow: hidden;
    max-height: calc(100% - 114px);
    border-radius: 5px 0 0 0;
    @include breakpoint(medium) {
        width: 17%;
        top: 110px;
    }
    .prevbutton-container {
        text-align: center;

        a {
            position:relative;
            display: inline-block;
            color: $color-meta-data-other;
            border-radius: 5px;
            margin: 5px 0;
            width:35px;
            height:35px;
            border: none;
            font-size:10px;
            text-indent:-200px;
            overflow:hidden;
            &:after {
                font-family: 'icomoon';
                content:'\ea41';
                width:35px;
                height:35px;
                top:5px;
                left:0px;
                text-indent:0px;
                position:absolute;
                font-size:26px;
                line-height:1em;
            }

            &:hover {
                color:#FFFFFF;
                cursor:pointer;
            }
        }
    }
    .image-box {
        width:100%;
        height:auto;
        margin:0px;
        padding:0px 10px 10px;
        text-align:center;
        border-width:0px;
        a {
            width: auto;
            height: auto;
            position:relative;
            display:inline-block;
            border:1px solid #000;
            color:$color-meta-data-other;

            &:hover {
                border:1px solid #fff;
                color:#fff;
            }
        }

        &.single-image-multiple-files {
            &:after {
                font-family: 'icomoon';
                content:'\e90e';
                position:absolute;
                color:#fff;
                top:5px;
                right:5px;
                font-size:20px;
                line-height:1em;
            }
        }
    }

    .album-page-image {
        position: relative;
        width:100%;
        max-width: 350px;
        max-height: 325px;
        top: 0;
        left: 0;
        transform: none;
    }

    .image-number {
        position:absolute;
        color:inherit;
        background:#000;
        font-weight:bold;
        padding:0px 5px;
        z-index:5;
        top:0px;
        left:0px;
    }

    .navigation-pages-right {
        position: absolute;
        top: 7px;
        left: 17px;
        right: 17px;
        margin: auto;
        display: flex;
        justify-content: center;
        @include breakpoint(medium) {
            position: absolute;
        }

        a,
        span {
            font-size: rem-calc(14);
            font-weight: 100;
        }

        a {
            color: $color-not-active-album;
        }

        .flex-item {
            position: relative;
            a {
                display:block;
                height:100%;
                background: $color-menu-border;
                padding: 4px 10px;
                &:hover {
                    color: #fff;
                }
            }
        }

        .navigation-before-after {
            font-size: rem-calc(10);
            color: $color-not-active-album;
            font-family: 'fontello';
            position: absolute;
        }

        .first {
            display: inline-block;
            a {
                border-radius: 7px 0 0 7px;
                padding-left: 20px;

                &::before {
                    content: '\e804';
                    top: 9px;
                    left: 6px;
                    @extend .navigation-before-after;
                    color:inherit;
                }

                &::after {
                    content: '\e804';
                    top: 4px;
                    left: 6px;
                    @extend .navigation-before-after;
                    color:inherit;
                }
            }
        }

        .page {
            display: inline-block;
            margin: 0 5px;
            padding: 0;
            width: 68px;
            input[type=text] {
                height: 100%;
                width: 100%;
                margin: 0;
                padding: 5px;
                border: 0;
                text-align: center;

            }
        }

        .go {
            display: inline-block;
            margin-right: 5px;
        }

        .last {
            display: inline-block;

            a {
                border-radius: 0 7px 7px 0;
                padding-right: 20px;

                &::before {
                    content: '\e800';
                    top: 9px;
                    right: 6px;
                    @extend .navigation-before-after;
                    color:inherit;
                }

                &::after {
                    content: '\e800';
                    top: 4px;
                    right: 6px;
                    @extend .navigation-before-after;
                    color:inherit;
                }
            }
        }
    }
    .album-page-content-right {
        margin-top:45px;
        max-height: calc(100vh - 159px);
        background:#000;
        overflow-y:auto;
    }
}

.close-button, .close-button.medium {
    right: 0.5rem;
    top: -0.7rem;
    width: 13px;
    height: 31px;
    &::after {
        font-size: 1.375rem;
        color: #ffffff;
        font-family: 'fontello';
        content: '\e80b';
        position: absolute;
        right: 1px;
        top: 13px;
    }
}

#modal-photograph,
.without-overlay {
    border-radius: 10px;
    background-color: $color-meta-data;
    border: none;
    outline: 0;
    z-index: 6;
    width: 520px;
    max-height: 60%;

    .info {
        margin-bottom: 15px;
    }

    .album-label {
        color: $color-text;
    }

    .label {
        color: $color-meta-data;
        margin: 0;
        background: none;
        padding: 0;
    }

    .element {
        margin-right: 15px;
        span {
            color: $color-meta-data-other;
            font-size: rem-calc(14);
            margin-right: 3px;
            a {
                color: $color-light-blue;
            }
        }

    }

}
#modal-photograph {
    border:5px solid $color-meta-data;
    width: calc(100vw - 40px);
    height: calc(100vh - 40px);
    max-height: calc(100vh - 40px);
    top:20px;
    left:20px;
    position:fixed;
    display:none;
    z-index:300;
    &.active {
        display:block;
    }

    &-overlay {
        position: fixed;
        z-index:295;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
    }

}

.refresh-modal-photograph,
.close-modal-photograph {
    height: 31px;
    width:31px;
    right:10px;
    position: absolute;

    .modal-photograph-close {
        &::after {
            font-size: rem-calc(26);
            color: $color-white;
            font-family: 'fontello';
            content: '\e80b';
            position: absolute;
            right: 8px;
            top: -4px;
        }

    }
}
.refresh-modal-photograph {
    right:40px;
    button {
        font-size: rem-calc(17);
        font-weight:bold;
        color: $color-white;
        position:absolute;
        top:6px;
        left:5px;
    }
}

.album-title-popup {
    padding-right:35px;
}

.image {
    .request_caption,
    .edit_metadata {
        width: 100%;
    }

}

.album {

    .edit_metadata {
        width: 100%;
        position: relative;
        margin-bottom: 11px;

        &:after {
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: $color-white;
            content: '';
            bottom: -8px;
            left: 0;
        }

    }

}

.album-content-container {

    #openseadragon-toolbar {
        position: absolute;
        bottom: 5%;
        left: calc(50% - 210px);
        z-index: 2;
        display: flex;
        button {
            background-color: $color-black;
            border: none;
            height: 40px;
            width: 40px;
            margin-right: 2px;
            padding: 9px;
        }
    }

    img {
        max-width: 100%;
    }

    ul {
        margin: 0;
        display: block;
        text-align: center;
    }

    li {
        margin: 12px 12px 0;
        padding: 4px;
        border: 1px solid $color-album-divider;
        @include breakpoint(medium) {
            display: inline-block;
        }
    }

    .album-pages-right-close {
        height: 45px;
        background-color: $color-active-album;
        position: absolute;
        width:100%;
        border-radius: 5px 0 0 0;

        .close-album-pages-right {
            width: 15px;
            height: 23px;
            margin-left: 10px;

            &::after {
                font-size: rem-calc(22);
                color: $color-white;
                font-family: 'fontello';
                content: '\e80b';
                position: absolute;
                left: 12px;
                top: -1px;
            }

        }


    }

}

#image_container {
    width: 100%;
    height: calc(100vh - 100px);

    .openseadragon-overlay {
        &:hover {
            border:5px solid rgba(#fff, 0.25);
            cursor: pointer;
        }
        &.has-description, &.has-description:hover {
            border:5px solid $color-light-blue;
        }

        &.has-multiple-images:hover {
            &:after {
                font-family: 'icomoon';
                content:'\e90e';
                position:absolute;
                color:#fff;
                bottom:20px;
                font-size:24px;
                line-height:1em;
                left:20px;
            }
        }
    }
}

    .ui-autocomplete {
        width: auto;
        display: inline-block;
        background: $color-white;
        padding: 5px 20px 15px;
        margin-top: 23px;
        border-radius: 0 0 10px 10px;
        z-index: 6;
        color: $color-border-homepage;
    }


.edit-metadata-container {
    color: $color-light-grey;

    .autocomplete,
    .multi {
        position: relative;

        .add_element {
            position: absolute;
            right: 8px;
            bottom: -2px;
            background-color: $color-light-blue;
            color: $color-white;
            font-weight: bold;
            padding: 6px 40px;
        }

    }

    .multi .add_element {
            bottom: -2px;
    }


    .description {
        margin: 2px 0 5px;
        color: $color-text;
        font-size: rem-calc(14);
    }

    .save-cancel-button {
        display: block;
        margin: 15px 0 10px;
    }

    .hidden {
        display: none;
    }

    .unlisted_element,
    .ui-autocomplete-input {
        margin: 1rem 0;
    }

    .album-label {
        font-size: rem-calc(16);
    }

    select,
    textarea {
        color: $color-border-homepage;
        font-size: rem-calc(14);
    }

    input {
        font-size: rem-calc(14);
    }
}

.lightbox-image {
    &.ui-draggable-dragging {
        max-width: 150px;
        max-height: 158px;
        min-width: 119px;
    }
}

#modal-editmetadata h3 {
    cursor: move;
}

.hidden {
    display: none;
}

#openseadragon-button-lightbox {
    color: #fff;
}
