.top-content {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  width: 100%;
  padding: 30px 2% 10px 2%;

    &__bio {
      order: 1;
      width: 100%;
        @include breakpoint(medium) {
            display: block;
            order: 2;
            width: 50%;
            padding: 0 15px 0 0;
        }
        @include breakpoint(large) {
            order: 1;
            width: 25%;
            padding: 0px 20px 0 0px;
        }
        p {
          font-size: rem-calc(15px);
          margin: 11px 0;
        }
    }

    &__slider {
        order: 1;
        margin-bottom: 20px;
        width: 100%;
        @include breakpoint(large) {
            order: 2;
            width: 48%;
        }
    }

    &__menu {
        order: 2;
        width: 100%;
        a {
            font-weight: 300;
            text-decoration: none;
            font-family: 'Muli', sans-serif;
        }
        .side-menu-parent {
          background-color: $color-menu-border;
          padding: 20px 20px 0 20px;
            .parent {
              padding-bottom: 22px;
            }
            li {
                .nav-header {
                    font-size: 1.125rem;
                }
                ul {
                    margin-left: 1.6rem;
                }
                a {
                    color: $color-logo-text;
                    padding: 0;
                    font-size: rem-calc(18);
                }
            }
            .side-menu-child {
                li {
                    display: list-item;
                    list-style-type: disc;
                    color: $color-logo-text;
                    padding-top: 2px;
                    a {
                        color: $color-light-blue;
                        font-size: rem-calc(16);
                        &:hover {
                            color: $color-white;
                        }
                    }
                }
            }
        }
        @include breakpoint(medium) {
            order: 3;
            width: 50%;
            padding-left: 8%;
        }
        @include breakpoint(large) {
            order: 3;
            width: 27%;
            padding-left: 20px;
        }
    }

    &__seperation {
        order: 4;
        width: 100%;
        .seperation-homepage {
            display: block;
            height: 2px;
            background-color: $color-border-homepage;
            margin: 30px auto 15px;
            padding: 0 20px;
        }
    }
}
