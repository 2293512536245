.lightbox-navigation {
    background-color: $color-menu-item;
    height: 50px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    &__slideshow {
        flex-basis: 95%;

        a,
        button {
            display: inline-block;
            background-color: $color-menu-grey-bottom;
            color: $color-meta-data-other;
            padding: 3px 10px;
            border-radius: 5px;
            font-size: 0.875rem;
            margin: 5px 0;
            border: none;

            &:hover {
                color: $color-white;
            }

        }

        .open-slideshow {
            margin-left: 15px;
        }

    }

    &__return {
        flex-basis: 5%;
        position: relative;
        a {
            font-size: rem-calc(16);
            color: $color-filter;
            display: block;
            text-align: center;
        }
        &::after {
            position: absolute;
            display: block;
            content: '';
            width: 1px;
            height: 50px;
            background-color: #2E2E2E;
            top: -13px;
            right: 0;
        }
    }
}

.edit-metadata-container {
    textarea {
        min-height: 275px;
    }
    .metadata_field_selector {
        width: 100%;
    }
}

.edit-lightbox-container {
    margin: 40px auto;
    max-width: 900px;
    width: 90%;


    h3 {
        background-color: $color-menu-border;
        border-radius: 10px 10px 0 0;
        margin: 0;
        padding: 20px;
        color: $color-white;
    }

    #add-lightbox {
        background-color: $color-active-album;
        border-radius: 0 0 10px 10px;
        padding: 10px 20px;

        label {
            display: inline-block;
        }

        #jform_is_public-lbl {
            margin-top: 10px;
        }

        input[type='submit']{
            background-color: $color-border-homepage;
            color: $color-meta-data-other;
            padding: 3px 10px;
            border-radius: 5px;
            font-size: rem-calc(16);
            margin: 5px 0;
            border: none;
            &:hover {
                color: $color-white;
            }
        }

    }

}

.com_lightbox .jscroll-results {
    padding: 1% 2% 7%;
}


.lightbox_resultlist {

    .jscroll-inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        .result {
            background-color: $color-menu-grey-bottom;
            margin: 10px;
            width: 100%;
            color: $color-meta-data-other;
            position: relative;

            @include breakpoint(large) {
                width: 48%;
            }

            .link-lightbox-item {
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 1;

                &:hover {
                    background:rgba(119, 119, 119, 0.3);
                }

            }

        }

        .lightbox-leftside,
        .lightbox-rightside {
            display: inline-block;
            height: 100%;
            max-height: 486px;
        }

        .lightbox-leftside {
            width: 59%;
            vertical-align: top;
            padding: 20px 8px 20px 20px;
            position: relative;

            .lightbox-buttons {
                position: absolute;
                bottom: 10px;
                z-index: 2;
            }

            .lightbox-title {
                background-color: transparent;
                margin:0;
                padding: 0;
                border-radius: none;
                color: $color-light-blue;
                font-size: rem-calc(18);
            }

            span {
                display: block;
                font-size: rem-calc(14);
            }

            a {
                display: inline-block;
                background-color: $color-border-homepage;
                color: $color-meta-data-other;
                padding: 3px 10px;
                border-radius: 5px;
                font-size: 0.875rem;
                margin: 5px 0;

                &:hover {
                    color: $color-white;
                }
            }

            .description {
                padding: 10px 0 30px 0;
                font-size: rem-calc(16);
                min-height: 74px;
            }

        }

        .lightbox-rightside {
            width: 40%;
            padding: 5px;
            position: relative;
            min-height: 252px;

            img {
                display: block;
                margin: 0 auto;
                max-width: 100%;
                position: absolute;
                top: 50%;
                left: 49%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }
        }
    }
}

.remove {
    padding: 6px 12px;
    display: block;
}

.item-x {
    position: relative;

    span {
        color: #D8D8D8;
        display: block;
        font-size: 0.8125rem;
        position: absolute;
        top: -7px;
        right: -5px;
    }
}

#lightbox_user {
    background-color: $color-active-album;
    position: relative;
    padding: 0;
    height: 28px;
    min-width: 310px;
    max-width: 350px;
    width: 40%;
    background-color: $color-active-album;
    margin-left: 1%;
    margin-top: 20px;
    border: none;
    color: $color-white;
    font-size: 0.875rem;
    appearance: menulist;
    -moz-appearance: menulist;
    -webkit-appearance: menulist;

}

.ui-dialog {
    border-radius: 10px;
    background: $color-active-album;
    width: 90% !important;
    margin: 0 !important;
    left: 5% !important;
    position: absolute !important;
    outline: none;
    z-index: 6;

    @include breakpoint(small) {
        width: 80% !important;
        left: 10% !important;
    }

    @include breakpoint(medium) {
        width: 50% !important;
        max-width: 500px;
        left: calc(50% - 250px) !important;
    }

    input[type='button'],
    button,
    .save-lightbox {
        background-color: $color-border-homepage;
        color: $color-meta-data-other;
        padding: 3px 20px;
        border-radius: 5px;
        font-size: 0.875rem;
        margin-right: 10px;
        border: 0;
        outline: none;

        &:hover {
            color: $color-white;
        }

    }

    .ui-dialog-content {
        height: auto !important;
        padding: 21px;
    }

    .ui-dialog-buttonset {
        margin: 0 0 12px 21px;
    }

    .ui-widget-content {
        h2, p {
            padding: 21px;
            text-align: center;
        }
    }

    .new-lightbox-container {
        width: auto;
        height: auto;
        border-radius: 10px;
        padding: 0 21px;
        background: $color-active-album;

        #jform_title,
        #jform_description {
            margin: 6px 0;
        }

    }

    .ui-dialog-titlebar {
        //background-color: $color-menu-border;
        background-color: rgba(17, 17, 17, 0.85);
        border-radius: 10px 10px 0 0;
        color: $color-white;
        padding: 10px 10px;

        .ui-dialog-title {
            margin-left: 15px;
        }

        .ui-button {
            font-size: 0px;
            line-height: 0;
            text-indent: -1000em;
            position: relative;
            border: none;
            padding: 0;
            outline: none;
            position: absolute;
            right: 10px;
            top: 5px;
            color: $color-meta-data-other;

            &:hover {
                color: $color-white !important;
            }

        }

    }

}

.lightbox-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 3;

    button {
        display: inline-block;
        background-color: $color-light-blue;
        color: $color-gray-black;
        padding: 3px 10px;
        border-radius: 5px;
        font-size: 0.875rem;
        margin: 5px 0;
        border: none;

        &:hover {
            background-color: $color-menu-grey-bottom;
            color: #C7C7C7;
        }
    }

    .lightbox-loadmore-container {
        text-align: center;
        background: #414141;
    }

    #lightbox-loadmore {
        margin: 10px auto;
    }

    .button-open {
        background-color: $color-active-album;
        text-align: center;
        margin: 0 auto;
        width: 200px;
        height: 42px;
        color: $color-white;
        border-radius: 10px 10px 0 0;
        padding: 8px 0;
        font-weight: 100;
        cursor: pointer;
        position: relative;
        @include breakpoint(large) {
            margin-left: 83%;
        }

        &:after {
            font-size: 12px;
            position: absolute;
            right: 12px;
            top: 15px;
            color: #dddddd;
            font-family: 'fontello';
            content: '\e800';
        }

        &:before {
            font-size: 12px;
            position: absolute;
            right: 12px;
            top: 8px;
            color: #dddddd;
            font-family: 'fontello';
            content: '\e804';
        }

    }

    .lining {
        background-color: $color-active-album;
        height: 0px;
    }

    .container-all {

        .lightbox-header {
            background-color: $color-active-album;
            padding: 17px 0;

            #lightboxes {
                position: relative;
                padding: 0;
                height: 28px;
                min-width: 310px;
                max-width: 350px;
                width: 40%;
                margin-left: 2%;
                background-color: $color-menu-border;
                border: none;
                color: $color-not-active-album;
                font-size: rem-calc(14);
                appearance: menulist;
                -moz-appearance: menulist; /* Firefox */
                -webkit-appearance: menulist; /* Safari and Chrome */
                // background: url(http://modernconflict.com/templates/amc/images/iconset-amc.png) no-repeat scroll 6px -510px #404040;

                &:before {
                    font-size: 12px;
                    position: absolute;
                    right: 12px;
                    top: 8px;
                    color: #dddddd;
                    font-family: 'fontello';
                    content: '\e803';
                }

            }

            .lightbox-option-container {
                margin: 10px 0 0 2%;

                @include breakpoint(medium) {
                    float: right;
                    display: inline-block;
                    margin: 0 2% 0 0;
                }

                .lightbox-option {
                    background-color: $color-menu-border;
                    color: $color-not-active-album;
                    font-size: rem-calc(14);
                    font-weight: 100;
                    padding: 5px 10px;
                    margin: 0;
                    &:hover {
                        background-color: $color-light-blue;
                    }
                }


                .add-lightbox {
                    border-radius: 10px 0 0 10px;
                }

                .share-lightbox {
                }

                .show-lightbox {
                    border-radius: 0 10px 10px 0;
                }

            }

        }

        .current-lightbox-images {
            background: $color-menu-border;
            padding: 15px;
            height: 342px;
            overflow: auto;

            .result {
                width: 185px;
                height: 170px;
                background-color: $color-background-content;
                padding: 10px;
                margin: 1.5px;
								position: relative;
								display: inline-block;

                img {
                    padding: 4px 0;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                }

                .items {
                    position: absolute;
                    top: 0;
                    right: 0;
                    background-color: $color-background-content;
                    width: 18.5px;
                    height: 18.5px;

                    .remove {
                        color: $color-not-active-album;
                        text-align: center;
                        display: block;
                        font-size: rem-calc(13);
                        padding: 3px 0;
                    }

                }

            }

        }

    }

}
