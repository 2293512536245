// 2. Breakpoints
// --------------

$breakpoints: (
    small: 0,
    medium: 768px,
    large: 1024px,
    xlarge: 1200px,
    xxlarge: 1440px,
);
$breakpoint-classes: (small medium large);

// 3. The Grid
// -----------

$grid-row-width: rem-calc(1200);
$grid-column-count: 12;
$grid-column-responsive-gutter: (
    small: 20px,
    medium: 30px,
);
$grid-column-align-edge: true;
$block-grid-max: 8;


// 22. Menu
// --------

$menu-margin: 0;
$menu-margin-nested: 1rem;
$menu-item-padding: 0.7rem 1rem;
$menu-item-color-active: $white;
$menu-item-background-active: get-color(primary);
$menu-icon-spacing: 0.25rem;
$menu-item-background-hover: $light-gray;
$menu-border: $light-gray;

// 24. Off-canvas
// --------------

$offcanvas-size: 250px;
$offcanvas-background: $light-gray;
$offcanvas-zindex: -1;
$offcanvas-transition-length: 0.5s;
$offcanvas-transition-timing: ease;
$offcanvas-fixed-reveal: true;
$offcanvas-exit-background: rgba($white, 0.25);
$maincontent-class: 'off-canvas-content';
$maincontent-shadow: 0 0 10px rgba($black, 0.5);
