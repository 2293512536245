.amc-content {
    width: 100%;
    height: 100%;
    background-color: $color-background-content;

    .article-container {
        display: flex;
        flex-wrap: wrap;
        padding: 0 5% 7%;

        &__intro {
            padding: 40px 0 10px;
            @include breakpoint(medium) {
                width: 100%;
            }
        }

        &__context {
            width: 100%;
            @include breakpoint(medium) {
                width: 70%;
                padding-top: 20px;
            }
        }

        &__sidemenu {
            width: 100%;
            margin-bottom: 20px;
            text-align: center;
            @include breakpoint(medium) {
              text-align: left;
            }
            a {
                font-weight: 300;
                text-decoration: none;
                font-family: 'Muli', sans-serif;
            }
            .side-menu-parent {
                li {
                    padding-top: 30px;
                    a {
                        color: $color-logo-text;
                        padding: 0;
                        font-size: rem-calc(18);
                        background: none;
                    }
                }
                li:first-child {
                  padding-top: 20px;
                }
                .side-menu-child {
                    padding: 5px 0 0 10px;
                    li {
                        display: list-item;
                        list-style-type: none;
                        color: $color-logo-text;
                        padding-top: 3px;
                        @include breakpoint(medium) {
                          list-style-type: disc;
                          padding-top: 2px;
                        }
                        a {
                            color: $color-light-blue;
                            font-size: rem-calc(16);
                            &:hover {
                                color: $color-white;
                            }
                        }
                    }
                }
            }
            @include breakpoint(medium) {
                width: 30%;
                padding-left: 20px;
            }
        }
    }
}
