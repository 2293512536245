/*
 * Micro clearfix hack
 * http://nicolasgallagher.com/micro-clearfix-hack/
 */
@mixin clearfix() {
    zoom: 1;

    &:before,
        &:after {
        content: "";
        display: table;
    }

    &:after {
        clear:both;
    }
}

/*
 * This hide-text is supposed to have better performance then the -9999px technique
 * http://www.zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement/
 */
@mixin hide-text() {
    text-indent: 110%;
    white-space: nowrap;
    overflow: hidden;
}

// Only visible for screen readers
@mixin visually-hidden() {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

@mixin button-reset() {
    margin: 0;
    padding: 0;
    -webkit-appearance: none;
    border: none;
}

@mixin ul-reset() {
    margin: 0;
    padding: 0;
    list-style: none;
}

// Center element inside its parent
@mixin center() {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

// For use with the gulp sprite plugin
@mixin sprite($icon-name) {
    $icon: map-get($icons, $icon-name);
    $sprite: map-get($icons, sprite);

    width: map-get($icon, width);
    height: map-get($icon, height);
    background-image: url(map-get($sprite, svgPath));
    background-position: map-get($icon, backgroundX) map-get($icon, backgroundY);
}

@mixin input-placeholder() {
    ::-webkit-input-placeholder {
        @content;
    }

    :-moz-placeholder { /* Firefox 18- */
        @content;
    }

    ::-moz-placeholder {  /* Firefox 19+ */
        @content;
    }

    :-ms-input-placeholder {
        @content;
    }
}

// Add percentage of white to a color
@function tint($color, $percent) {
    @return mix(white, $color, $percent);
}

// Add percentage of black to a color
@function shade($color, $percent) {
    @return mix(black, $color, $percent);
}

@mixin hover-transition() {
    transition: all 0.2s ease-out;
}
