.lightbox {
    width: 100vw;
    height:100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: $color-black;
    z-index: 5;
    outline: 0;
    display: none;

    .close-slideshow {
      display: block;
      position: absolute;
      top: 0;
      font-size: rem-calc(30);
      color: $color-white;
      background-color: transparent;
      z-index: 6;
      @include button-reset();
      outline: 0;
    }

    .img-fill {
      width: 100vw !important;
    }

    .lb-images {
      max-width: 100%;
      max-height: 100%;

      .lb-slides {
        max-width: 100%;
        max-height: 100%;

        .slick-dots {
            display: flex !important;
            justify-content: center;
            bottom: 70px;
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            li {
                display: inline-block;
                padding: 0 10px;

                button {
                    border-radius: 50%;
                    border: none;
                    width: 15px;
                    height: 15px;
                    font-size: 0;
                    outline: 0;
                    background-color: $color-white;
                }
            }

            .slick-active {
                button {
                    background-color: $color-light-blue;
                }
            }
        }

        @mixin carousel-button() {
          @include button-reset();
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 36px;
          height: 36px;
          background: none;

          i {
            font-size: 30px;
            line-height: 30px;
            color: $color-white;
          }
        }

        .button-prev {
          @include carousel-button();
          left: 2%;
          z-index: 1;
          outline: none;
        }

        .button-next {
          @include carousel-button();
          right: 2%;
          z-index: 1;
          outline: none;
        }

            .img-fill {
              width: 100vw;
              height: 100vh;
              display:block;
              outline: 0;

              img {
                max-width: 100%;
                max-height: 100%;
                display: block;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                margin: 0 auto;


                &:focus {
                  outline: 0;
                }
              }
        }
      }
    }
}

.slideshow-container {
  position: fixed;
  top: 0;
  left: 0;
  background: $color-black;
  z-index: 5;

    .slick-dots {
        display: flex !important;
        justify-content: center;
        margin-top: 8px;
        margin: 8px 0 0 0;

        li {
            display: inline-block;
            padding: 0 10px;

            button {
                border-radius: 50%;
                border: none;
                width: 15px;
                height: 15px;
                font-size: 0;
                outline: 0;
                background-color: $color-white;
            }
        }

        .slick-active {
            button {
                background-color: $color-slider-active;
            }
        }
    }

    img {
    }

    .slick-dots {
      position: absolute;
      bottom: 40px;
      left: calc(50% - 150px);
    }

    @mixin carousel-button() {
      @include button-reset();
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 36px;
      height: 36px;
      background: none;

      i {
        font-size: 30px;
        line-height: 30px;
        color: $color-white;
      }
    }

    .button-prev {
      @include carousel-button();
      left: 0;
      z-index: 1;
      outline: none;
    }

    .button-next {
      @include carousel-button();
      right: 0;
      z-index: 1;
      outline: none;
    }

}



.picturae-carousel {

    article {
        position: relative;
        max-height: 660px;
        overflow: hidden;

        &:hover {
            h2 {
                visibility: visible;
            }

            .button {
                visibility: visible;
            }

            .caption__content {
                background: rgba(0,0,0,0.1);
            }
        }
    }

    .slick-dots {
        display: flex !important;
        justify-content: center;
        margin-top: 8px;
        margin: 8px 0 0 0;
        li {
            display: inline-block;
            padding: 0 10px;
            button {
                border-radius: 50%;
                border: none;
                width: 15px;
                height: 15px;
                font-size: 0;
                outline: 0;
                background-color: $color-black;
            }
        }
        .slick-active {
            button {
                background-color: $color-slider-active;
            }
        }
    }

    img {
        position: relative;
        width: 100%;
    }

    &__slides {
        position: relative;
        text-align: center;
    }

    &__button {
        background: rgba(0,0,0,0.1);
        cursor: pointer;
        color: $color-white;
        font-size: 2.5em;
        top: 50%;
        display: none !important;

        &.icon-arrow-left {
            left: 10px;
        }

        &.icon-arrow-right {
            right: 10px;
        }

        @include breakpoint(large) {
            display: block !important;
            position: absolute;
        }
    }

    &__caption {
        display: inline-block;
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;

        .caption__content {
            background: none;
            color: $color-white;
            padding: 5px;
            display: inline-block;
            text-align: center;
            max-width: 60em;
        }

        h2 {
            visibility: hidden;
            font-size: 3em;
        }

        .button {
            display: none;
            font-size: 1.2em;
            margin-top: 5px;

            @include breakpoint(large) {
                display: inline-block;
                visibility: hidden;
            }
        }
    }
}
