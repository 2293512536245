.landing-page {
    width: 100%;
    .intro-logo {
        text-align: center;
        margin-top: 100px;
        .small-title,
        .big-title {
            display: inline-block;
            color: $color-logo-text;
            text-transform: uppercase;
            font-family: 'Droid Serif', serif;
        }
        .small-title {
            font-size: rem-calc(15);
            @include breakpoint(medium) {
                font-size: rem-calc(19.5);
            }
        }
        .big-title {
            font-size: rem-calc(22.5);
            @include breakpoint(medium) {
                font-size: rem-calc(30);
            }
        }
    }
    .wrap {
        width: 65%;
        height: auto;
        margin: 15px auto;
        max-width: 500px;
        a {
            color: $color-logo-text;
            font-size: rem-calc(14);
            &:hover {
                color: $color-white;
            }
        }
    }
    #form-login-remember {
        display: none;
    }
    .input-prepend {
        .add-on {
            display: none;
        }
        input {
            width: 250px;
            height: 35px;
            margin: 5px 0;
        }
    }
    .controls {
        button {
            background: $color-light-blue;
            border: 0;
            color: $color-white;
            width: 100px;
            display: block;
            margin: 5px auto 15px;
            &:hover {
                color: $color-text;
            }
        }
    }
    .unstyled {
        display: none;
    }

    .login,
    .reset,
    .remind,
    .reset-confirm,
    .reset-complete {
        fieldset {
            border: 0;
            margin: 0;
            padding: 0;
            label {
                color: $color-light-grey;
            }
        }
    }

    .login {
        .control-group:nth-child(3) {
            .control-label,
            .controls {
                display: inline-block;
                margin-right: 5px;
            }
        }
        .control-group {
          input {
              margin: 0 0 5px 0;
          }
        }
    }

    .reset,
    .remind {
        .control-group {
          margin-top: 10px;
        }
    }

    .nav-stacked {
        li {
            list-style-type: none;
            text-align: center;
        }
    }
}
