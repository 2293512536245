#system-message-container {
  display: none;
}

.login {
  #system-message-container {
      @include z-index(system-message);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      display: block;
      width: 600px;
      margin: 0 auto;
      .alert-message {
          .alert-message,
          .alert-heading,
          .close {
              display: none;
          }
      }

      .alert-warning {
        background: $color-light-grey;
        border-radius: 0 0 10px 10px;
        padding: rem-calc(15);
      }

      h4 {
          font-size: rem-calc(24);
          color: $color-black;
      }

      .alert-message {
          color: $color-black;
      }

      .alert {
          position: relative;
      }

      .close {
          position: absolute;
          right: 0;
          top: 7px;
          width: 40px;
          height: 40px;
          text-align: center;
          text-decoration: none;
          font-size: 30px;
          line-height: 20px;
          color: $color-black;

          &:hover {
              cursor: pointer;
          }
      }
  }
}
