.error-container {
    width: 100%;
    height: 100%;
    text-align: center;
    margin-top: 100px;
    position: relative;

    img {
        max-width: 100%;
    }

    .content-box {
        position: absolute;
        width: 400px;
        height: calc(100% - 6px);
        top: 0;
        left: 0;
        background-color: rgba($color-black, 0.8);
        text-align: left;
        padding: 30px;

        @include breakpoint(medium) {
            left: 20%;
        }

        h1, h2, h3 {
            color: $color-white;
        }

        p, ol {
            color: $color-light-grey;
        }

        li {
            list-style-type: inherit;
        }

        strong {
            color: $color-white;
        }
    }

}
