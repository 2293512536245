.comment_list { 
    margin-top: 30px;

    ul {
        position: relative;

        &:before {
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: $color-white;
            content: '';
            top: -19px;
        }

        .comment {
            margin-bottom: 20px;

            h3 {
                font-size: rem-calc(17px);
            }

            h4 {
                font-size: rem-calc(14px);

                .label {
                    color: $color-meta-data !important;
                    margin: 0 !important;
                    background: none;
                    padding: 0;
                }

            }

        }

    }

}

.comment_form {

    h2 {
        color: $color-text;
        font-size: 1.5rem;
    }

    input {
        margin-top: 15px;
    }

    textarea {
        min-height: 100px;
    }

}
